// src/enums/Http.ts
var HttpMethods = /* @__PURE__ */ ((HttpMethods2) => {
  HttpMethods2["Get"] = "GET";
  HttpMethods2["Post"] = "POST";
  HttpMethods2["Put"] = "PUT";
  HttpMethods2["Patch"] = "PATCH";
  HttpMethods2["Delete"] = "DELETE";
  return HttpMethods2;
})(HttpMethods || {});
var HttpStatus = /* @__PURE__ */ ((HttpStatus2) => {
  HttpStatus2[HttpStatus2["None"] = 0] = "None";
  HttpStatus2[HttpStatus2["Ok"] = 200] = "Ok";
  HttpStatus2[HttpStatus2["Created"] = 201] = "Created";
  HttpStatus2[HttpStatus2["Accepted"] = 202] = "Accepted";
  HttpStatus2[HttpStatus2["NonAuthoritativeInformation"] = 203] = "NonAuthoritativeInformation";
  HttpStatus2[HttpStatus2["NoContent"] = 204] = "NoContent";
  HttpStatus2[HttpStatus2["ResetContent"] = 205] = "ResetContent";
  HttpStatus2[HttpStatus2["PartialContent"] = 206] = "PartialContent";
  HttpStatus2[HttpStatus2["MultiStatus"] = 207] = "MultiStatus";
  HttpStatus2[HttpStatus2["AlreadyReported"] = 208] = "AlreadyReported";
  HttpStatus2[HttpStatus2["IMUsed"] = 209] = "IMUsed";
  HttpStatus2[HttpStatus2["BadRequest"] = 400] = "BadRequest";
  HttpStatus2[HttpStatus2["Unauthorized"] = 401] = "Unauthorized";
  HttpStatus2[HttpStatus2["PaymentRequired"] = 402] = "PaymentRequired";
  HttpStatus2[HttpStatus2["Forbidden"] = 403] = "Forbidden";
  HttpStatus2[HttpStatus2["NotFound"] = 404] = "NotFound";
  HttpStatus2[HttpStatus2["MethodNotAllowed"] = 405] = "MethodNotAllowed";
  HttpStatus2[HttpStatus2["NotAcceptable"] = 406] = "NotAcceptable";
  HttpStatus2[HttpStatus2["ProxyAuthenticationRequired"] = 407] = "ProxyAuthenticationRequired";
  HttpStatus2[HttpStatus2["RequestTimeout"] = 408] = "RequestTimeout";
  HttpStatus2[HttpStatus2["Conflict"] = 409] = "Conflict";
  HttpStatus2[HttpStatus2["Gone"] = 410] = "Gone";
  HttpStatus2[HttpStatus2["LengthRequired"] = 411] = "LengthRequired";
  HttpStatus2[HttpStatus2["PreconditionFailed"] = 412] = "PreconditionFailed";
  HttpStatus2[HttpStatus2["PayloadTooLarge"] = 413] = "PayloadTooLarge";
  HttpStatus2[HttpStatus2["RequestUriTooLong"] = 414] = "RequestUriTooLong";
  HttpStatus2[HttpStatus2["UnsupportedMediaType"] = 415] = "UnsupportedMediaType";
  HttpStatus2[HttpStatus2["RequestedRangeNotSatisfiable"] = 416] = "RequestedRangeNotSatisfiable";
  HttpStatus2[HttpStatus2["ExpectationFailed"] = 417] = "ExpectationFailed";
  HttpStatus2[HttpStatus2["Teapot"] = 418] = "Teapot";
  HttpStatus2[HttpStatus2["InternalServerError"] = 500] = "InternalServerError";
  HttpStatus2[HttpStatus2["NotImplemented"] = 501] = "NotImplemented";
  HttpStatus2[HttpStatus2["BadGateway"] = 502] = "BadGateway";
  HttpStatus2[HttpStatus2["ServiceUnavailable"] = 503] = "ServiceUnavailable";
  HttpStatus2[HttpStatus2["GatewayTimeout"] = 504] = "GatewayTimeout";
  HttpStatus2[HttpStatus2["HttpVersionNotSupported"] = 505] = "HttpVersionNotSupported";
  HttpStatus2[HttpStatus2["VariantAlsoNegotiates"] = 506] = "VariantAlsoNegotiates";
  HttpStatus2[HttpStatus2["InsufficientStorage"] = 507] = "InsufficientStorage";
  HttpStatus2[HttpStatus2["LoopDetected"] = 508] = "LoopDetected";
  HttpStatus2[HttpStatus2["NotExtended"] = 510] = "NotExtended";
  HttpStatus2[HttpStatus2["NetworkAuthenticationRequired"] = 511] = "NetworkAuthenticationRequired";
  HttpStatus2[HttpStatus2["NetworkConnectionTimeoutError"] = 599] = "NetworkConnectionTimeoutError";
  return HttpStatus2;
})(HttpStatus || {});

// src/GraphQLApi/GraphQLApi.ts
import { request } from "graphql-request";
var GraphQLApi = class {
  constructor(baseUrl, headers) {
    this.baseUrl = baseUrl;
    if (headers)
      this.headers = headers;
  }
  async ExecuteRequest(query, variables) {
    try {
      const response = await request({
        url: this.baseUrl,
        document: query,
        variables,
        requestHeaders: this.headers
      });
      return this.handleSuccess(response);
    } catch (err) {
      return this.handleError(err);
    }
  }
  handleSuccess(response) {
    const _response = {
      error: false,
      status: 200 /* Ok */,
      body: response
    };
    return _response;
  }
  handleError(error) {
    const _response = {
      error: true,
      errorMessage: error,
      status: 0 /* None */,
      body: null
    };
    return _response;
  }
};

// src/RestApi/RestApi.ts
import axios from "axios";
import { getFlowId } from "@maverick/logger/dist/Logger";
var RestRequest = class {
  constructor(init) {
    Object.assign(this, init);
  }
};
var RestApi = class {
  constructor(baseUrl, headers, responseMiddleware) {
    this.baseUrl = baseUrl;
    this.responseMiddleware = responseMiddleware;
    if (headers)
      this.headers = headers;
  }
  async ExecuteRequest(request2) {
    let headers = this.headers ?? {};
    if (!!request2.auth)
      headers.authorization = request2.auth;
    if (!!request2.headers)
      headers = { ...headers, ...request2.headers };
    headers["x-flow-id"] = getFlowId();
    const _request = {
      baseURL: this.baseUrl,
      url: request2.endpoint,
      method: request2.method,
      headers,
      data: request2.body,
      responseType: request2.responseType ?? "json",
      retry: request2.retry
    };
    let responseHandled;
    try {
      const response = await axios(_request);
      responseHandled = this.handleSuccess(response);
    } catch (err) {
      responseHandled = this.handleError(err);
    }
    return responseHandled;
  }
  handleSuccess(response) {
    const { status, data } = response;
    const _response = {
      error: false,
      errorClient: false,
      errorServer: false,
      errorNetwork: false,
      status,
      body: data
    };
    return this.responseMiddleware ? this.responseMiddleware(_response) : _response;
  }
  handleError(error) {
    const _response = { error: true, status: 0 /* None */, body: null };
    if (error && error.response) {
      const { status, data } = error.response;
      _response.errorClient = status >= 400 && status <= 499;
      _response.errorServer = status >= 500;
      _response.status = status;
      _response.body = data;
    } else {
      _response.errorNetwork = true;
    }
    return this.responseMiddleware ? this.responseMiddleware(_response) : _response;
  }
  // TODO: The code below is overriding the error response status and body coming from Olo api. We'll change this before sprint 39 is finished on 2/21
  // private static statusShouldRetry(statusCode: number): boolean {
  // 	if (statusCode >= 500 && statusCode <= 599 && statusCode !== 501) {
  // 		return true;
  // 	}
  // 	const retryStatusCodes = [429, 408, 504];
  // 	return retryStatusCodes.includes(statusCode);
  // }
};
export {
  GraphQLApi,
  HttpMethods,
  HttpStatus,
  RestApi,
  RestRequest
};
